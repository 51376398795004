.call-to-action-form {
    background: url(../../Assets/Images/Call-To-Action.webp) no-repeat center 70%;
    background-size: cover;
    background-attachment: fixed;
}

.parent-form {
    width: min(90%,800px);
    margin: 0 auto;
    padding: 40px 45px;
    padding-top: 130px;
    background-color: #f4f4f4e9;
    color: var(--text-color);
    position: relative;
}

.parent-form .title-md {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--primary-color);
    padding: 30px;
    text-align: center;
    font-weight: bold;
    font-size: 28px;
}

.parent-form form {
    margin-top: 20px;
}

/* Responsive */
@media (max-width:600px) {
    .call-to-action-form .title-md {
        font-size: 26px;
    }
    
    .parent-form {
        width: 100%;
        padding: 15px;
        padding-top: 115px;
    }
}