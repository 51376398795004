.parallax {
    background: url(../../Assets/Images/Home-Parallax.webp) no-repeat center center fixed;
    background-size: cover;
}

.parallax .title-md {
    color: #fff;
}

.parallax .hed-section {
    margin-bottom: 0;
}

.slider {
    width: 100%;
    min-height: 380px;
    position: relative;
}

.slide-item {
    position: absolute;
    inset: 0;
    visibility: hidden;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition-duration: 1s;
}

.slide-img {
    width: 140px;
    height: 140px;
    border-radius: 50%;
}

.slide-content {
    margin: 30px 0;
    letter-spacing: 2px;
    font: italic lighter 18px / 1.8 sans-serif;
    color: #fff;
}

.slider .title-sm {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    color: #fff;
}

/* radios */
.radios {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    column-gap: 10px;
}

.radio {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid #fff;
    cursor: pointer;
    transition-duration: 0.25s;
}

.radio.target {
    background-color: #fff;
}

.radio:hover {
    background-color: #fff !important;
}

/* Responsive */
@media (max-width: 768px) {
    .slider {
        min-height: 420px;
    }
}

@media (max-width:600px) {
    .slider {
        min-height: 470px;
    }
}

@media (max-width:426px) {
    .slider {
        min-height: 520px;
    }
}

@media (max-width:376px) {
    .slider {
        min-height: 580px;
    }
}