.parent-map {
    width: 100%;
    height: 100%;
    padding: 30px 20px;
    background-color: #404040;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 25px;
    text-align: center;
    color: #fff;
    position: relative;
}

#main .parent-map {
    min-height: 60vh;
}

.parent-map p {
    font-size: 18px;
}

.map {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
}