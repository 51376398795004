.sharing-box {
    margin: 60px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.social-networks {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.networks-icon {
    padding: 12px;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #fff;
}

.networks-icon:has(.icon-facebook) {
    background-color: #3b5998;
}   
.networks-icon:has(.icon-twitter) {
    background-color: #55acee;
}
.networks-icon:has(.icon-linkedin) {
    background-color: #0077b5;
}
.networks-icon:has(.icon-whatsapp) {
    background-color: #77e878;
}
.networks-icon:has(.icon-pinterest) {
    background-color: #bd081c;
}
.networks-icon:has(.icon-email) {
    background-color: #000000;
}