.footer {
  background-color: #1f1f1f;
  border-top: 12px solid #404040;
  text-align: left;
  position: relative;
}

.footer > .container {
  padding: 80px 4%;
  align-items: stretch;
}

.footer .parent-items {
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
}

.footer-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 20px;
}

.footer-item:has(.footer-logo) {
  row-gap: 25px;
}

.footer .title-sm {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 16px;
  color: var(--primary-color);
}

.footer-logo {
  height: 75px;
}

.footer #tweet {
  max-width: 100%;
  height: 100%;
}

.footer .list {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.footer .list-item {
  display: flex;
}

.list-item p {
  text-transform: capitalize;
  line-height: 1.8;
}

.list-icon {
  margin: 4px 10px 0 0;
}

/* map */
.footer .parent-map p {
  font-size: inherit;
}

/* footer nav */
.footer-nav {
  width: 100%;
  background-color: #121212;
  position: absolute;
  top: calc(100% - 1%);
  left: 0;
}

.footer-nav .container {
  padding: 23px 4%;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 100px;
}

.footer-nav p {
  text-transform: uppercase;
  font-size: 12px;
}

.footer-nav .text-link {
  color: #b7b6b6;
}

.social-media {
  display: flex;
  margin-right: 50px;
}

.social-media-icon {
  margin: 0 8px;
  padding: 4px;
  font-size: 18px;
  transition-duration: 0.25s;
}

.social-media-icon:hover {
  opacity: 0.7;
}

/* Responsive */
@media (max-width: 1024px) {
  .footer .parent-items {
    row-gap: 60px;
  }

  .footer-item:has(.footer-logo) {
    grid-column: span 2;
    align-items: center;
    text-align: center;
  }

  .footer-item:has(.parent-map) {
    grid-column: span 2;
    min-height: 300px;
  }
}

@media (max-width: 768px) {
  .footer-item:has(.footer-logo),
  .footer-item:has(.parent-map) {
    grid-column: span 1;
  }

  .footer iframe {
    min-height: 300px;
  }
}

@media (max-width: 600px) {
  .footer-item:has(.footer-logo) {
    align-items: flex-start;
    text-align: left;
  }

  .footer-nav .container {
    flex-direction: column;
    text-align: center;
    row-gap: 25px;
  }

  .social-media {
    margin-right: 0;
  }
}
