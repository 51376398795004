/* Raleway-Extrabold */
@font-face {
    font-family: 'raleway-extrabold';
    src: 
        url('../Fonts/Raleway/Raleway-Extrabold.woff2') format('woff2'),
        url('../Fonts/Raleway/Raleway-Extrabold.woff') format('woff'),
        url('../Fonts/Raleway/Raleway-Extrabold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

/* Raleway-Medium */
@font-face {
    font-family: 'raleway-medium';
    src: 
        url('../Fonts/Raleway/Raleway-Medium.woff2') format('woff2'),
        url('../Fonts/Raleway/Raleway-Medium.woff') format('woff'),
        url('../Fonts/Raleway/Raleway-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

/* Raleway-Regular */
@font-face {
    font-family: 'raleway-regular';
    src:
        url('../Fonts/Raleway/Raleway-Regular.woff2') format('woff2'),
        url('../Fonts/Raleway/Raleway-Regular.woff') format('woff'),
        url('../Fonts/Raleway/Raleway-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}