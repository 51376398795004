.undefined {
    text-align: left;
    align-items: flex-start;
    row-gap: 20px;
}

.undefined .search-box {
    width: 100%;
}

.undefined .subline {
    width: 100%;
    padding-bottom: 40px;
    margin-bottom: 20px;
}

.undefined .subline::after {
    width: 100%;
    border-bottom-color: #dddddd;
}