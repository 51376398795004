.search-box {
    all: unset;
    display: flex;
}

.search-box .form-item {
    margin-top: 0;
}

.search-box .form-item:hover {
    border-color: #dddddd;
}

.btn-search {
    position: relative;
    padding: 13px 16px;
}

.submit-search {
    background-color: #000000;
    border: none;
    cursor: pointer;
    position: absolute;
    inset: 0;
    transition-duration: 0.25s;
}

.submit-search:hover { 
    background-color: var(--primary-color);
}

.btn-search .icon-search {
    cursor: pointer;
    position: relative;
    z-index: 2;
    color: white;
}

/* Responsive */
@media (max-width: 768px) {
    .sharing-box {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 20px;
    }
}