.tooltip {
    position: relative;
}

.tooltip::before,
.tooltip::after {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 50%;
    bottom: calc(100% + 15px);
    transform: translateX(-50%);
    transition-duration: 0.25s;
}

.tooltip::before {
    content: attr(data-tooltip);
    padding: 5px 10px;
    background-color: var(--secondary-color);
    border-radius: 3px;
    font: 12px 'raleway-regular';
    color: #fff;
    text-transform: capitalize;
}

.tooltip::after {
    content: '';
    border: 5px solid transparent;
    border-top-color: var(--secondary-color);
    transform: translate(-50%,9px);
}

.tooltip:hover::after,
.tooltip:hover::before {
    opacity: 1;
    visibility: visible;
}